export const Environment = {
  defaultLanguage: "EN",
  mandator: {
    reference: "FBEF6445-4F25-EE11-A9BB-0022488400D6",
    name: "KYC Demo",
  },
  api: {
    baseUrl: "https://kyc.dfx.swiss/v1",
    jwtSecret: "d479fbe1-08d5-4732-9833-9f04e5ae9ed9",
  },
};
